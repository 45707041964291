.shortInterestfilter {
  display: flex;
  justify-content: flex-end;
   /* padding: 1px; */
  background-color: #000000;
  line-height: 100%;
}

.shortInterestfilter .ui.selection.dropdown {
  border: 1px solid #54C8FF;
  background-color: #FF9E28;
  width: 250px;
  margin-right: 5px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.shortInterestfilter .visible .menu.transition {
  background-color: #FF9E28;
}

.shortInterestfilter  .ui.selection.dropdown .menu > .item {
  border-top: 1px solid #FAFAFA;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: #FF9E28;
  font-size: 10px;
  padding: 0 0;
}

.shortInterestfilter .ui.selection.dropdown {
  line-height: 100%;
}

.shortInterestfilter .ui.search {
  font-size: 10px !important;
  line-height: 4px;
}

.dropdown.icon {
  line-height: 4px;
}

.ui.selection.dropdown > .search.icon, .ui.selection.dropdown > .delete.icon, .ui.selection.dropdown > .dropdown.icon {
  line-height: 4px;
}

.ant-tabs-tab-prev-icon, .ant-tabs-tab-next-icon {
  color: white;
}

.ant-tabs-nav .ant-tabs-tab:hover {
  color: white;
}

.recharts-responsive-container {
  border: 1px solid grey;
  margin-top: 10px;
  left: 1%;
}