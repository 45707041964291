.treasuryDashboardWrapper {
    /* flex: 1;
    display: flex; */
    height: 100%;
    width: 100%;
}

.treasuryDashboardWrapper {
    /* flex: 1;
    display: flex; */
    height: 100%;
    width: 100%;
}

.treasuryDashboardWrapper .ant-tabs {
    height: 100%;
}

.treasuryDashboardWrapper .ant-tabs-content {
    height: 100%;
}
.treasuryDashboardWrapper .recharts-legend-item-text {
    color: white;
}


