.riskDashboardWrapper {
    /* flex: 1;
    display: flex; */
    height: 100%;
    width: 100%;
}

.riskDashboardWrapper {
    /* flex: 1;
    display: flex; */
    height: 100%;
    width: 100%;
}

.riskDashboardWrapper .ant-tabs {
    height: 100%;
}

.riskDashboardWrapper .ant-tabs-content {
    height: 100%;
}

.riskDashboardWrapper .SplitPane.horizontal {
  position: relative !important;
}

.riskDashboardWrapper .ui.inverted.block.header {
  background-color: #1B1C1D;
  margin-bottom: 1px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 13px;
  border: 0px;
  text-align: center;
}