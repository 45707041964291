.portfolioDashboardWrapper {
  /* flex: 1;
  display: flex; */
  height: 100%;
  width: 100%;
}

.portfolioFilter {
  display: flex;
  justify-content: space-between;
  padding: 1px;
  background-color: #1B1C1D;
  height: 36px;
}

.ant-cascader-picker {
  width: 170px;
  background-color: #FF9E28;
  font-size: 12px;
}

.ant-cascader-input.ant-input {
  height: 100%;
}

.ant-cascader-menu-item {
  background-color: #FF9E28;
  font-size: 12px;
  line-height: 16px;
}

.ant-cascader-menu {
  background-color: #FF9E28;
  width: 80px;
  min-width: 80px;
}

.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled) {
  background: rgba(0, 0, 0, 0.05);
  font-weight: 600;
}

.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  background: rgba(0, 0, 0, 0.05);
  z-index: 13;
  font-weight: 600;
}

.ant-cascader-menu-item:hover {
  background: rgba(0, 0, 0, 0.05);
  z-index: 13;
}

.ant-cascader-picker-clear {
  background-color: transparent;
}

.ant-input {
  height: 30px;
  /* font-size: 10px; */
}

.liveDiv {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: #1B1C1D;
  align-items: center;
  line-height: 100%;
}

.liveDiv .ui.green.button {
  font-size: 12.5px;
}