.omsDialogOperationBar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1px;
    height: 36px;
}

.omsDialogOperationBar .rightOperations {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    line-height: 100%;
}

.omsDialogOperationBar .rightOperations > * {
    margin-left: 3px;
}

.omsDialogOperationBar .ant-upload-list {
    display: relative;
    float: right;
}

.omsDialogOperationBar *::placeholder {
    /* modern browser */
    color: black;
}

.omsForm .ant-row .ant-form-item {
    margin-bottom: 1px;
    padding-bottom: 1px;
}