.ui.mini.statistics .statistic>.value,
.ui.mini.statistic>.value {
  font-size: 1rem !important;
  text-transform: none;
}

.ui.statistic+.ui.statistic {
  margin: 0em;
}

.ui.segment {
  padding: 1px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.ui.inverted.segment,
.ui.primary.inverted.segment {
  background: #2d3436;
}

.ui.segment {
  padding: 3px !important;
}

.ui.mini.inverted.blue.statistic > .value {
  font-size: 1rem !important;
  font-weight: 500;
}

.ui.mini.inverted.red.statistic > .value {
  font-size: 1rem !important;
  font-weight: 500;
}

.ui.mini.inverted.green.statistic > .value {
  font-size: 1rem !important;
  font-weight: 500;
}

.ui.segment .statisticItem {
  min-width: 60px;
}

@media only screen and (min-width: 320px) and (max-width: 568px) {
  .ui.segment {
    justify-content: flex-start;
  }

  .ui.segment .statisticItem {
    flex-basis: calc(100% / 4);
  }
}

@media only screen and (min-width: 375px) and (max-width: 667px) {
  .ui.segment {
    justify-content: flex-start;
  }

  .ui.segment .statisticItem {
    flex-basis: calc(100% / 5);
  }
}

.status-fail-ani {
  font-weight: 700;
  font-style: italic; 
  color: white;
}