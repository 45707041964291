.orderBlotterWrapper {
  /* flex: 1;
  display: flex; */
  height: 100%;
  width: 100%;
}

.orderBlotterWrapper .SplitPane.horizontal {
  position: relative !important;
}

/* for data grid, since no menu shown for each column lable, then column width should be reduced. */
.orderBlotterWrapper .ag-theme-balham-dark .ag-header-cell-label {
  width: calc(100% - 1px);
}

.orderBlotterWrapper .ag-theme-balham-dark .ag-cell {
  padding-left: 1px;
  padding-right: 1px;
}

.filterBar {
  height: 100%;
}

.filterBar .ui.inverted.block.header {
  background-color: #1B1C1D;
  margin-bottom: 3px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 13px;
  border: 0px;
}

.filterBar .ant-tree {
  background: transparent;
}

.filterBar .ant-tree-title {
  color: #FF9E28;
  font-size: 11px;
  font-weight: 500;
  font-style: italic;
}

.filterBar .keyword {
  font-weight: 500;
  font-style: italic;
}

.filterBar .ant-tree-checkbox-inner {
  width: 12px;
  height: 12px;
}

.filterBar .ant-tree-checkbox-checked .ant-tree-checkbox-inner:after {
  border-color: #1B1C1D;
  left: 2px;
  top: 4px;
}

.filterBar .ant-tree .ant-tree-treenode {
  padding: 0px;
  height: 21px;
}

.filterBar .header {
  text-align: center;
}

.filterBar .ag-theme-balham-dark .ag-row-even {
  background-color: #262c2e;
}

.filterBar .ag-theme-balham-dark .ag-row-selected {
  background-color: #005880;
}

.Resizer {
  background: #000;
  opacity: .2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 18px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 18px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}