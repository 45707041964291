.orderDashboardWrapper {
    /* flex: 1;
    display: flex; */
    height: 100%;
    width: 100%;
}

.orderDialogOperationBar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1px;
  height: 36px;
}

.orderDialogOperationBar .rightOperations {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  line-height: 100%;
}

.orderDialogOperationBar .rightOperations > * {
  margin-left: 3px;
}

.orderDialogOperationBar .ant-upload-list {
  display: relative;
  float: right;
}